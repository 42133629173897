
import {
    getInsureData,
    getMoneyTrend,
    getPeopleStatistics,
    PlanStatisticsDto,
    addLongTermRecharge
} from '@/api/long-insure'
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import tabCapsule from '@/components/tab-capsule.vue'
import popupRecharge from '@/components/popup-recharge.vue'
import user from '@/utils/user'
import { ElMessage } from 'element-plus'
import sunny from '@/components/dialog/sunny.vue'
import { getUserInfo, getqueryreceiptaccountapi, getsaasCompanyinfoapi } from '../api/base'

console.log(2121212121, user.openBank)
export default defineComponent({
    name: 'LongInsure',
    components: { tabCapsule, popupRecharge, sunny },
    data() {
        return {
            listone: [],
            listtwo: [],
            maxone: 0,
            maxtwo: 0,
            total1: 0,
            total2: 0,
            showtcflag: true,
            accountBalance: '',
            allEnsureCount: '',
            companyPlans: [] as any,
            isDeletePopup: false,
            peopleStatistics: {} as PlanStatisticsDto,
            statisticsTab: 0,
            peopleStatisticsType: 0,
            isTrendEmpty: false,
            isRecharge: false,
            openBank: '',
            bankNo: '',
            bankName: '',
            showSunnyDialog: false,
            user: user
        }
    },
    async created() {
        // const data = await getUserInfo()
        const data: any = await getqueryreceiptaccountapi({ productType: 3 })
        console.log(data, '117843256398567348')
        this.openBank = data.openBank
        this.bankNo = data.bankNo
        this.bankName = data.bankName
        this.getInsureData()
        this.getMoneyTrend()
        this.onPeopleStatisticsType()
        if (sessionStorage.getItem('tcflag') === 'true') {
            this.showtcflag = true
        } else {
            this.showtcflag = false
        }
    },
    methods: {
        toInsureDetail(id: number) {
            this.$router.push({
                path: '/long-insure/detail/' + id
            })
        },
        closetc() {
            this.showtcflag = false
            sessionStorage.setItem('tcflag', 'true')
        },
        longcz() {
            if (this.accountBalance === null) {
                ElMessage({
                    type: 'warning',
                    message: '温馨提示：您还没有开通该功能权限，如有需要请联系销售'
                })
            } else {
                this.isRecharge = true
            }
        },
        async getInsureData() {
            // planList: result.companyPlans.map(item=>{
            //     item.isEnd = item.endTime ?  (new Date(item.endTime).getTime() < new Date().getTime()) : false
            //     return item;
            // })
            const result = await getInsureData()
            this.companyPlans = result.companyPlans
            this.accountBalance = result.accountBalance
            this.allEnsureCount = result.allEnsureCount

            const hasYg = this.companyPlans.some(
                (i: any) => i.insurCompany === 'period_003_yg'
            )

            if (hasYg && !localStorage.getItem('sunnyNoticeClose')) {
                this.showSunnyDialog = true
            }

            console.log(this.companyPlans, 'companyPlans')
        },
        async onPeopleStatisticsType() {
            await this.getPeopleStatistics()
            this.onPeopleStatisticsTab(this.statisticsTab)
        },
        async getPeopleStatistics() {
            this.total1 = 0
            this.total2 = 0
            let res: any = await getPeopleStatistics({
                policyStatus: this.peopleStatisticsType ? '1' : ''
            })
            console.log(res, 'gsuighiudsghuisuhi')
            let max1 = 0
            let max2 = 0
            if (res.occupationGroup.length !== 0) {
                res.occupationGroup.forEach((ele: any, i: number) => {
                    this.total1 += ele.count
                    if (max1 < ele.count) {
                        max1 = ele.count
                    }
                })
            }
            if (res.serveCompanyGroup.length !== 0) {
                res.serveCompanyGroup.forEach((ele: any, i: number) => {
                    this.total2 += ele.count
                    if (max2 < ele.count) {
                        max2 = ele.count
                    }
                })
            }
            this.maxone = max1
            this.maxtwo = max2
            this.listone = res.occupationGroup
            this.listtwo = res.serveCompanyGroup
            this.peopleStatistics = res
        },
        onPeopleStatisticsTab(tab: number) {
            this.statisticsTab = tab
            const element = document.getElementById(
                'people-statistics'
            ) as HTMLElement
            echarts.dispose(element)
            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} <br/>{c}人  ({d}%)'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '80%'],

                        label: {
                            position: 'inner',
                            formatter: '{b} {c}人'
                        },
                        data: [] as { value: number; name: string }[]
                    }
                ]
            }
            const charts = echarts.init(element)
            if (tab === 0) {
                option.series[0].data = this.peopleStatistics.occupationGroup.map(
                    item => ({
                        value: item.count,
                        name: item.occupationName
                    })
                )
            } else {
                option.series[0].data = this.peopleStatistics.serveCompanyGroup.map(
                    item => ({
                        value: item.count,
                        name: item.serveCompanyName
                    })
                )
            }
            charts.setOption(option)
        },
        async getMoneyTrend() {
            const statistics = await getMoneyTrend()
            console.log(statistics, 'statistics')
            if (statistics?.length === 0) {
                this.isTrendEmpty = true
                return
            }
            console.log('打印啦吗')
            setTimeout(() => {
                const element = document.getElementById('trend') as HTMLElement
                console.log(element, 'element')
                echarts.dispose(element)
                const option = {
                    xAxis: {
                        type: 'category',
                        data: statistics.map(item => item.ensureDay)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    grid: {
                        left: '30px',
                        right: '30px',
                        top: '30px',
                        bottom: '30px'
                    },
                    series: [
                        {
                            name: '人数',
                            data: statistics.map(item => item.peopleCount),
                            type: 'bar'
                        },
                        {
                            name: '金额',
                            data: statistics.map(item => item.money),
                            type: 'bar'
                        }
                    ]
                }
                console.log(option, 'option')
                const charts = echarts.init(element)
                charts.setOption(option)
                this.isTrendEmpty = false
            }, 300)
        },
        // 充值弹窗点击确定关闭
        async onDetermine(num: string, ocrNum:string, ocrImg: string, isRepeat: boolean) {
            if (!num) {
                this.$message.error('请输入金额')
                return
            }
            if (!ocrImg) {
                this.$message.error('请上传凭证')
                return
            }
            const result = await addLongTermRecharge({
                rechargeAmount: num,
                ocrAmount: ocrNum,
                voucherUrl: ocrImg,
                receiptId: user.receiptId,
                isRepeat: isRepeat
            })
            if (result) {
                // this.isRecharge = false
                this.$message.success('提交成功')
            }
        }
    }
})
