
import { defineComponent } from 'vue'
import { reportDetailInfo } from '@/api/long-insure'
import addMaterial from '@/components/add-material.vue';

export default defineComponent({
    name: 'LongInsureReportDetail',
    components: {
        addMaterial
    },
    data () {
        return {
            processList: [] as any,
            caseInfo: {} as any,
            showAdd: false
        }
    },
    created () {
        this.getReportDetailInfo()
    },
    methods: {
        async getReportDetailInfo () {
            const result = await reportDetailInfo({
                id: this.$route.params.id
            })
            console.log(result, 'result')
            result.reportCaseResultList.forEach((item:{reportSolveResult:string, remark:string, createTime:string, desc:number, type:string, hollow:boolean}) => {
                this.processList.push({
                    type: 'primary',
                    hollow: true,
                    reportSolveResult: item.reportSolveResult,
                    timestamp: item.createTime,
                    remark: item.remark,
                    desc: item.desc
                })
            })
            this.caseInfo = result
        },
        showAddMaterial() {
            this.showAdd = true
        }
    }
})
