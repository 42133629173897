
import store from '@/store'
import dayjs from 'dayjs'
import { defineComponent } from 'vue'
import {
    getInsureDetailInfo,
    getDispatchList,
    insureAdd,
    insureCheckReduce,
    insureReduce,
    checkoutIdCardNoList,
    checkoutIdCardNoListTwo,
    addListInformation,
    renewalMonthNotify,
    testingInsure
} from '@/api/long-insure'
import personnelQueryPopup from '../components/personnel-query-popup.vue'
import AlertBox from '@/components/alert-box.vue'
import ExcelJS from 'exceljs'
import { ElLoading, ElMessage } from 'element-plus'
import { getCardAge, formTesting, dateFormat } from '@/utils/util'
import { getsaasCompanyinfoapi } from '@/api/base'

export default defineComponent({
    name: 'LongInsureAddSubtract',
    components: {
        personnelQueryPopup,
        AlertBox
    },
    data() {
        return {
            specialAppointisSelect: false,
            showmore: true,
            isopenmore: true,
            specialAppoint: '1、仅承保视频中的对应风险等级工种，其它工种请再提供视频确认2、超风险投保警示:严格按照实际工种对应风险等线进行投保，严禁超风险投保，否则保险公司有权拒赔。如将4类及以上工种投保至1-3类工种属于超风险投保工种',
            insurance: 'add' as string | string[], // 保险状态 add 加保 reduce 减保
            selectionList: [] as string[],
            tioInfo: {} as any,
            date: '',
            isPopup: false,
            addList: [] as any,
            addCloneList: [] as addListInformation[],
            cloneAddList: [] as addListInformation[],
            addPageNum: 0,
            addPageSize: 100000,
            isEditPopup: false,
            isEditTypePopup: '',
            companyDetailList: [] as any, // 派遣单位详细信息列表
            companyDetailId: '',
            companyDetailName: '',
            workTypeList: [] as any[],
            workTypeId: '',
            workTypeName: '',
            fileList: [] as { name: string }[],
            userList: [] as any,
            isManualPopup: false,
            manualName: '',
            manualCode: '',
            isUninsuredPopup: false,
            uninsuredList: [] as any,
            selectedIdCardNo: 0,
            isRemindPopup: false, // 确认提交提醒弹窗
            isSuccessPopup: false, // 加保 或 减保 成功弹窗
            companyList: [] as string[],
            searchType: '',
            isCheckout: false,
            enterErrorList: {},
            companyPlanId: '' as string | string[],
            formErrorList: [] as { name: string; code: string }[],
            isFormError: false,
            datePickerDisabled: false,
            insureRenewal: '',
            isRenew: false,
            isRenewAmount: false,
            testingInsureInfo: {} as {
                price?: number;
                priceFour?: number;
                priceFive?: number;
                needCreateCount?: number;
                deductionCount?: number;
                needCostFeeMonth?: number;
                tomorrowNeedCostFee?: number;
                currentBalance?: number;
                willEnsureCostFee?: number;
            },
            loaded: false
        }
    },
    async created() {
        console.log(this.$route.params, 'this.$route.params')
        this.insurance = this.$route.params.type
        if (this.$route.params.echo) {
            this.insureRenewal = this.$route.params.echo as string
            await this.onNextMonth()
            await this.getRenewalMonthNotify()
        }
        if (this.$route.params.type === 'add') {
            (store.state.titles[2] as string) = '加保'
        } else {
            (store.state.titles[2] as string) = '减保'
        }
        this.getInsureDetailInfo()
        this.companyPlanId = this.$route.params.id
    },
    methods: {
        downLoadTemp() {
            window.open('https://bullet001.oss-cn-shanghai.aliyuncs.com/file/template/投保人员名单模板.xlsx')
        },
        changeisopenmore(i: number) {
            if (i === 1) {
                this.isopenmore = false
            } else {
                this.isopenmore = true
            }
        },
        sglr() {
            this.isManualPopup = true
            this.specialAppointisSelect = false
            this.workTypeId = ''
            this.companyDetailId = ''
            this.manualCode = ''
            this.manualName = ''
        },
        // 显示弹窗弹窗
        onDisplayIsEditPopup(type: string) {
            this.specialAppointisSelect = false
            this.isEditTypePopup = type
            this.isEditPopup = true
        },
        // 基本信息
        async getInsureDetailInfo() {
            const result = await getInsureDetailInfo({
                planId: this.$route.params.id,
                month: ''
            })
            this.tioInfo = result
            if (result.insurCompany === 'period_015_gwy') {
                this.datePickerDisabled = true
                if (this.$route.params.type === 'add') {
                    this.date = dayjs().add(1, 'day').format('YYYY-MM-DD')
                    ElMessage({ message: '此方案加保时间不可修改', type: 'warning' })
                } else {
                    this.date = dayjs().format('YYYY-MM-DD')
                    ElMessage({ message: '此方案减保时间不可修改', type: 'warning' })
                }
            }
        },
        // 在线选择
        onlineSelection() {
            this.isPopup = true
        },
        // 减保复选框
        checkMaterialStatus(row: any, column: any, cellValue: any, index: any) {
            // this.addList.filter(item => item.errMsg)
            if (row.errMsg === '未投保') {
                // eslint-disable-next-line no-unused-expressions
                return false
            } else {
                return true
            }
        },
        // 减保样式变化
        reduceTable(row: any) {
            if (row.row.errMsg === '未投保') {
                return 'background-color: rgba(230,230,230,0.1);' // 修改的样式
            } else {
                return 'color: #000000'
            }
        },
        // 减保未投保排序
        changeSort(row: any) {
            // eslint-disable-next-line no-constant-condition
            const nums1 = '未投保' ? 1 : 0
            const nums2 = 0
            return nums1 - nums2
        },
        // 在线选择完成
        async insureComplete(event: addListInformation[]) {
            event.forEach((item: addListInformation, index: number) => {
                event[index].joinType = 1
            })
            this.addList.push(...event)
            this.addCloneList.push(...event)
            if (this.$route.params.type === 'reduce') {
                this.addList.sort((a: any, b: any) => {
                    return (b.errMsg ? 1 : 0) - (a.errMsg ? 1 : 0)
                })
            }
            // 去重
            for (let i = 0; i < this.addList.length; i++) {
                for (let j = i + 1; j < this.addList.length; j++) {
                    if (this.addList[i].idCardNo === this.addList[j].idCardNo) {
                        this.addList.splice(j, 1)
                        j = j - 1
                    }
                }
            }
            await this.interceptList()
            this.isPopup = false
            this.preparationRecord()
        },
        // 筛选公司列表
        preparationRecord() {
            this.searchType = ''
            // this.companyList = []
            // this.addList = this.addCloneList
            const arr = this.addCloneList
                .map((item: { serveCompanyName: string }) => {
                    return item.serveCompanyName
                })
                .filter((n: null | string) => {
                    return n
                })
            this.companyList = arr.reduce(function (
                prev: string[],
                next: string
            ) {
                prev.indexOf(next) === -1 && prev.push(next)
                return prev
            },
                [])
        },
        // 截取数据
        interceptList() {
            this.cloneAddList = this.addList.slice(
                (this.addPageNum ? this.addPageNum - 1 : 0) * this.addPageSize,
                (this.addPageNum ? this.addPageNum : this.addPageNum + 1) *
                this.addPageSize
            )
        },
        // 单个删除
        async deletePersonnel(idCardNo: string) {
            this.addList = this.addList.filter(
                (item: { idCardNo: string }) => item.idCardNo !== idCardNo + ''
            )
            // await this.interceptList()
            this.$message.success('删除成功！')
            this.addCloneList = this.addCloneList.filter(
                (item: { idCardNo: string }) => item.idCardNo !== idCardNo + ''
            )
            this.preparationRecord()
        },
        // 批量删除
        async batchDelete() {
            this.selectionList.forEach((item: string, index: number) => {
                this.addList = this.addList.filter(
                    (ite: { idCardNo: string }) =>
                        item + '' !== ite.idCardNo + ''
                )
                this.addCloneList = this.addCloneList.filter(
                    (ite: { idCardNo: string }) =>
                        item + '' !== ite.idCardNo + ''
                )
            })
            // this.addCloneList = this.addList
            this.preparationRecord()
            this.$message.success('批量删除成功！')
        },
        // 勾选或
        handleSelectionChange(event: addListInformation[]) {
            this.selectionList = event.map((item: addListInformation) => {
                return item.idCardNo
            })
        },
        // 编辑人员信息
        async editPersonnelInfo(id: number, type: string, row: any) {
            console.log(44444, row)
            this.specialAppointisSelect = false
            this.companyDetailId = row.serveCompanyId
            this.companyDetailList = [
                {
                    serveCompanyName: row.serveCompanyName,
                    serveCompanyId: row.serveCompanyId
                }
            ]

            this.workTypeId = row.occupationId
            this.workTypeList = row.occupationList || []

            if (!row?.occupationList?.length) {
                await this.getCompanyDetailList()
                const cur = this.companyDetailList.find(
                    (i: any) => i.serveCompanyId === row.serveCompanyId
                )
                if (cur) {
                    this.workTypeList = cur.occupationList
                }
            }

            this.isEditTypePopup = type
            this.selectedIdCardNo = id
            const userInfo = this.addList.filter(
                (item: addListInformation) =>
                    this.selectedIdCardNo + '' === item.idCardNo
            )
            const { userName, idCardNo } = userInfo[0]
            this.manualName = userName
            this.manualCode = idCardNo
            this.isEditPopup = true
        },
        // 获取派遣单位详细信息
        async getCompanyDetailList() {
            this.loaded = false
            const result = await getDispatchList({
                pageNum: 1,
                pageSize: 99999,
                status: 1,
                isGetFile: 1,
                searchText: '',
                insurCompany: this.tioInfo.insurCompany,
                planCode: this.tioInfo.insurCompanyPlanCode,
                isGetArea: 1
            })
            this.loaded = true
            this.companyDetailList = result.list
        },
        // 选择派遣单位
        async onSelectCompany(e: any) {
            let result: any = await getsaasCompanyinfoapi({ planId: this.$route.params.id })
            this.specialAppointisSelect = false
            this.companyDetailList.forEach((item: any, i: number) => {
                if (e === item.serveCompanyId) {
                    if (item.isBlacklist || item.isForbidden) {
                        this.companyDetailId = ''
                        // } else if (item.area === '天津' && this.tioInfo.insurCompany === 'period_006_gyas') {
                    } else if (item.area === '天津' && !result.isWhitelist && this.tioInfo.insurCompany === 'period_006_gyas') {
                        ElMessage.warning('抱歉，当前方案不支持该（天津）地区投保')
                        this.companyDetailId = ''
                    } else {
                        this.specialAppoint = '1、仅承保视频中的对应风险等级工种，其它工种请再提供视频确认2、超风险投保警示:严格按照实际工种对应风险等线进行投保，严禁超风险投保，否则保险公司有权拒赔。如将4类及以上工种投保至1-3类工种属于超风险投保工种'
                    }
                }
            })
            if (this.companyDetailId) {
                const arr: any = this.companyDetailList.filter(
                    (item: {
                        serveCompanyId: string | number;
                        occupationList: any;
                    }) => {
                        if (item.serveCompanyId === this.companyDetailId) {
                            return item
                        }
                    }
                )
                console.log(11111, arr)
                this.workTypeList = arr[0].occupationList
                this.workTypeId = ''
            } else {
                this.workTypeList = []
                this.workTypeId = ''
            }
        },
        // 取消更改
        onCancelModification() {
            this.manualName = ''
            this.manualCode = ''
            this.workTypeList = []
            this.isEditPopup = false
        },
        // 确定更改
        async onConfirmModification() {
            if (!this.specialAppointisSelect && this.specialAppoint) {
                ElMessage({
                    message: '请勾选特别约定',
                    type: 'warning'
                })
            } else if (!this.workTypeId) {
                ElMessage({
                    message: '请选择工种',
                    type: 'warning'
                })
            } else {
                // 多选编辑
                const company: any = this.companyDetailList.filter(
                    (item: any) => item.serveCompanyId === this.companyDetailId
                )
                const workType: any = this.workTypeList.filter(
                    (item: any) => item.id === this.workTypeId
                )

                console.log(1111, workType, this.workTypeList, this.addList)
                if (this.isEditTypePopup === 'batch') {
                    this.selectionList.forEach((item: string) => {
                        this.addList.forEach(
                            (ite: addListInformation, index: number) => {
                                if (item === ite.idCardNo) {
                                    this.addList[index].serveCompanyName =
                                        company[0].serveCompanyName
                                    this.addList[index].occupationName =
                                        workType[0].name
                                    this.addList[
                                        index
                                    ].serveCompanyId = this.companyDetailId
                                    this.addList[
                                        index
                                    ].occupationId = this.workTypeId
                                    this.addList[index].level =
                                        workType[0].level
                                    this.addList[
                                        index
                                    ].occupationList = this.workTypeList
                                    this.addList[
                                        index
                                    ].specialAppoint = this.specialAppoint
                                    // this.addList[index].userName = this.manualName
                                    // this.addList[index].idCardNo = this.manualCode
                                }
                            }
                        )
                    })
                    this.addList.sort((a: any, b: any) => {
                        return (a.errMsg ? 1 : 0) - (b.errMsg ? 1 : 0)
                    })
                    this.isEditPopup = false
                    this.companyDetailList = []
                    this.workTypeList = []
                    this.companyDetailId = ''
                    this.workTypeId = ''
                } else {
                    // 单选编辑
                    const loading = await ElLoading.service({
                        fullscreen: true,
                        text: '姓名身份证校验中...'
                    })
                    // 身份证号姓名校验
                    const checkoutIdCardNoResTwo = await checkoutIdCardNoListTwo(
                        [
                            {
                                userName: this.manualName,
                                idCardNo: this.manualCode
                            }
                        ]
                    )
                    if (checkoutIdCardNoResTwo.code === 1) {
                        //  弹窗展示错误人员
                        this.$message.error(
                            checkoutIdCardNoResTwo.data[0].msg as string
                        )
                        await loading.close()
                        return
                    }
                    this.addList.forEach(
                        (ite: addListInformation, index: number) => {
                            if (this.selectedIdCardNo + '' === ite.idCardNo) {
                                // 只有加保才需要 赋值 工种名字
                                if (this.insurance === 'add') {
                                    this.addList[index].serveCompanyName =
                                        company[0].serveCompanyName
                                    this.addList[index].occupationName =
                                        workType[0].name
                                    this.addList[index].level =
                                        workType[0].level
                                    this.addList[
                                        index
                                    ].serveCompanyId = this.companyDetailId
                                    this.addList[
                                        index
                                    ].occupationId = this.workTypeId
                                }
                                this.addList[index].userName = this.manualName
                                this.addList[
                                    index
                                ].specialAppoint = this.specialAppoint
                                this.addList[
                                    index
                                ].idCardNo = this.manualCode.replace(/x/g, 'X')
                            }
                        }
                    )
                    if (this.$route.params.type === 'reduce') {
                        const test = this.addList.map((item: any) => {
                            return {
                                userName: item.userName,
                                idCardNo: item.idCardNo
                            }
                        })
                        const checkoutIdCardNoRes = await checkoutIdCardNoList({
                            companyPlanId: Number(this.$route.params.id),
                            workerInfoList: test as [
                                { userName: string; idCardNo: string | number }
                            ]
                        })
                        for (let i = 0; i < this.addList.length; i++) {
                            for (let j = i + 1; j < this.addList.length; j++) {
                                if (
                                    this.addList[i].idCardNo ===
                                    this.addList[j].idCardNo
                                ) {
                                    this.addList.splice(j, 1)
                                    j = j - 1
                                }
                            }
                        }
                        if (checkoutIdCardNoRes.errorList.length > 0) {
                            for (
                                let i = 0;
                                i < checkoutIdCardNoRes.errorList.length;
                                i++
                            ) {
                                for (let j = 0; j < this.addList.length; j++) {
                                    if (
                                        checkoutIdCardNoRes.errorList[i]
                                            ?.idCardNo ===
                                        this.addList[j]?.idCardNo
                                    ) {
                                        this.addList[j].errMsg = '未投保'
                                        i++
                                    } else {
                                        this.addList[j].errMsg = ''
                                    }
                                }
                            }
                        } else {
                            for (let j = 0; j < this.addList.length; j++) {
                                this.addList[j].errMsg = ''
                            }
                        }

                        this.addList.sort((a: any, b: any) => {
                            return (b.errMsg ? 1 : 0) - (a.errMsg ? 1 : 0)
                        })
                    }

                    this.isEditPopup = false
                    this.companyDetailList = []
                    this.workTypeList = []
                    this.companyDetailId = ''
                    this.workTypeId = ''
                }
                // this.addCloneList = this.addList
                await this.preparationRecord()
            }
        },
        search() {
            if (this.searchType !== '') {
                this.addList = this.addCloneList
                const arr = this.addList.filter(
                    (item: addListInformation) =>
                        item.serveCompanyName ===
                        this.companyList[Number(this.searchType)]
                )
                this.addList = arr
            } else {
                return (this.addList = this.addCloneList)
            }
        },
        onReset() {
            this.searchType = ''
            this.addList = this.addCloneList
        },
        close() {
            this.enterErrorList = {}
        },
        // 上传
        async onUpFile(file: File) {
            console.log(77777, file)
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '名单信息校验中...'
            })
            const userList = [] as {
                userName: string;
                idCardNo: string;
                serveCompanyName: string;
                occupationName: string;
                joinType: number;
                errMsg?: string;
            }[]
            const reader = new FileReader()
            const errorList = [] as any
            reader.onload = async e => {
                const workbook = new ExcelJS.Workbook()
                await workbook.xlsx.load(
                    (e.target as FileReader).result as ArrayBuffer
                )
                console.log(workbook.getWorksheet(1), 'workbookworkbookworkbook')

                workbook.getWorksheet(1).eachRow(async (item, index) => {
                    // 单个检验表格文字是否正常
                    const arrError = formTesting(
                        item.getCell(1).value as any,
                        item.getCell(2).value as any
                    )
                    this.formErrorList.push(arrError)
                    if (index <= 1) {
                        return item
                    }
                    userList.push({
                        userName:
                            arrError.name || (item.getCell(1).value as string),
                        idCardNo:
                            arrError.code ||
                            (item.getCell(2).value as string).replace(
                                /x/g,
                                'X'
                            ),
                        serveCompanyName: item.getCell(3).value as string,
                        occupationName: item.getCell(4).value as string,
                        joinType: 2
                    })
                })
                this.formErrorList = this.formErrorList.filter(
                    item => item.name
                )
                // if (this.formErrorList.length > 0) {
                //   // formErrorList: [] as string[],
                //   // isFormError: false
                //   this.isFormError = true
                //   // this.$message.error(errorList.toString() + ',表单格式错误')
                //   await loading.close()
                //   // return
                // }

                const staffList = userList.map(
                    (
                        item: {
                            idCardNo: string;
                            joinType: number;
                            serveCompanyName: string;
                            userName: string;
                        },
                        index: number
                    ) => {
                        return {
                            userName: item.userName,
                            idCardNo: item.idCardNo
                            // index: index + 1
                        }
                    }
                )
                if (this.$route.params.type === 'add') {
                    const verification = await checkoutIdCardNoListTwo(
                        userList.map((item, index) => {
                            return {
                                userName: item.userName,
                                idCardNo: item.idCardNo,
                                index: index + 1
                            }
                        })
                    )
                    this.enterErrorList = verification.data
                    // if (verification.code === 1) {
                    //     this.enterErrorList = verification.data
                    //     return
                    // }
                    if (verification.data) {
                        return
                    } else {
                        this.userList = userList
                    }
                    // this.userList = userList
                    // this.handleCurrentChange(1)
                } else {
                    const verificationTwo = await checkoutIdCardNoListTwo(
                        userList.map((item, index) => {
                            return {
                                userName: item.userName,
                                idCardNo: item.idCardNo,
                                index: index + 1
                            }
                        })
                    )
                    this.enterErrorList = verificationTwo.data
                    // if (verification.code === 1) {
                    //     this.enterErrorList = verification.data
                    //     return
                    // }
                    if (verificationTwo.data) {
                        return
                    }

                    const verification = await checkoutIdCardNoList({
                        companyPlanId: Number(this.$route.params.id),
                        workerInfoList: staffList as [
                            {
                                index?: number | undefined;
                                userName: string;
                                idCardNo: string | number;
                                msg?: string | undefined;
                            }
                        ]
                    })

                    // eslint-disable-next-line no-unused-expressions
                    for (let i = 0; i < verification.errorList.length; i++) {
                        for (let j = 0; j < userList.length; j++) {
                            if (
                                verification.errorList[i].idCardNo ===
                                userList[j].idCardNo
                            ) {
                                userList[j].errMsg = '未投保'
                            }
                        }
                    }
                    // userList = [...verification.errorList, ...verification.correctList]
                    // this.enterErrorList = verification?.errorList

                    // if (verification?.errorList) {
                    //     return
                    // } else {
                    // eslint-disable-next-line no-undef

                    // }
                }
                const unit = await getDispatchList({
                    pageNum: 1,
                    pageSize: 50,
                    status: 1,
                    isGetFile: 1,
                    searchText: '',
                    insurCompany: this.tioInfo.insurCompany,
                    planCode: this.tioInfo.insurCompanyPlanCode
                })
                const name: {
                    serveCompany: any;
                    name: any;
                    occupationId: number | null;
                    serveCompanyId: number | null;
                    occupationList: [];
                }[] = []

                unit.list.forEach((item: any) => {
                    console.log(8999, item)
                    if (item.occupationList.length === 1) {
                        // occupationId:
                        name.push({
                            serveCompany: item?.serveCompanyName,
                            name: item.occupationList[0].name,
                            occupationId: item?.occupationList[0].id,
                            serveCompanyId: item?.serveCompanyId,
                            occupationList: item?.occupationList
                        })
                    } else {
                        name.push({
                            serveCompany: item?.serveCompanyName,
                            name: null,
                            occupationId: null,
                            serveCompanyId: item?.serveCompanyId,
                            occupationList: item?.occupationList
                        })

                        if (this.tioInfo.type === 3) {
                            item.occupationList = item.occupationList.filter(
                                (item: any) => {
                                    return item.level !== '5'
                                }
                            )
                        }
                        const result = item.occupationList.sort(
                            (a: any, b: any) => {
                                return b.level - a.level
                            }
                        )
                        // item.occupationList.sort((item:any) => {
                        //     item.occupationList[0].level
                        // })
                        // name.push(serveCompany:result[0].)
                        name[name.length - 1].name = result[0]?.name
                        name[name.length - 1].occupationId = result[0]?.id
                    }
                    return name
                })
                userList.forEach((item: any) => {
                    const curIndex = name.findIndex(
                        i => item.serveCompanyName === i.serveCompany
                    )
                    if (curIndex !== -1) {
                        item.serveCompanyId = name[curIndex].serveCompanyId
                        item.occupationList = name[curIndex].occupationList
                        if (item.occupationName) {
                            const curOcpation = item.occupationName.replace(
                                /[\(|（]\d\W[\)|）]/g,
                                ''
                            )
                            const idx = name[curIndex].occupationList.findIndex(
                                (j: any) => j.name === curOcpation
                            )
                            if (idx !== -1) {
                                item.occupationId = (name[curIndex]
                                    .occupationList[idx] as any).id
                                item.occupationName = (name[curIndex]
                                    .occupationList[idx] as any).name
                                item.level = (name[curIndex].occupationList[
                                    idx
                                ] as any).level
                            }
                        }
                    }
                    // name.map((items: any) => {
                    //   if (
                    //     item.serveCompanyName &&
                    //     item.serveCompanyName === items.serveCompany
                    //   ) {
                    //     item.occupationName = items.name
                    //     item.occupationId = items.occupationId
                    //     item.serveCompanyId = items.serveCompanyId
                    //     return item
                    //   }
                    //   return item
                    // })
                })
                // if(userList.)
                this.userList = userList

                console.log(666666, this.userList)
                //       as [{
                //   errMsg: string
                //   idCardNo: string
                //   userName: string
                // }]
                // .errorList

                // if (verification.code === 1) {
                //     this.enterErrorList = verification.data
                //     return
                // }

                // this.userList = userList
                // this.handleCurrentChange(1)
                // 校验通过
                await loading.close()
                this.addList.push(...this.userList)

                console.log(9999, this.addList)
                for (let i = 0; i < this.addList.length; i++) {
                    for (let j = i + 1; j < this.addList.length; j++) {
                        if (
                            this.addList[i].idCardNo ===
                            this.addList[j].idCardNo
                        ) {
                            this.addList.splice(j, 1)
                            j = j - 1
                        }
                    }
                }
                this.addList.sort((a: any, b: any) => {
                    return (b.errMsg ? 1 : 0) - (a.errMsg ? 1 : 0)
                })
                this.addCloneList = this.addList
                this.interceptList()
                await this.preparationRecord()
            }
            reader.readAsBinaryString(file)
            this.fileList.push({
                name: file.name
            })
        },
        // 手动录入
        async onManualEntry() {
            this.manualName = this.manualName.replace(/\s*/g, '')
            this.manualName = this.manualName.replace(/\./g, '·')
            this.manualCode = this.manualCode.replace(/\s*/g, '')
            this.manualCode = this.manualCode.replace(/x/g, 'X')
            if (!this.specialAppointisSelect && this.specialAppoint) {
                ElMessage({
                    message: '请勾选特别约定',
                    type: 'warning'
                })
                return
            }
            if (!this.manualCode) {
                this.$message.error('请输入身份证')
                return
            }
            const age = getCardAge(this.manualCode)
            if (age < 16) {
                this.$message.error('小于16岁不可投保')
                return
            }
            if (!this.manualName) {
                this.$message.error('请输入姓名')
                return
            }
            if (!this.workTypeId && this.insurance === 'add') {
                this.$message.error('请选择工类')
                return
            }
            if (!this.companyDetailId && this.insurance === 'add') {
                this.$message.error('请输入派遣单位')
                return
            }
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '姓名身份证校验中...'
            })
            let errMsg = ''
            if (this.$route.params.type === 'reduce') {
                // 身份证号姓名校验
                const checkoutIdCardNoResTwo = await checkoutIdCardNoListTwo([
                    {
                        userName: this.manualName,
                        idCardNo: this.manualCode
                    }
                ])
                if (checkoutIdCardNoResTwo.code === 1) {
                    this.$message.error(
                        checkoutIdCardNoResTwo.data[0].msg as string
                    )
                    await loading.close()
                    return
                }

                // 身份证号姓名校验
                const checkoutIdCardNoRes = await checkoutIdCardNoList({
                    companyPlanId: Number(this.$route.params.id),
                    workerInfoList: [
                        {
                            userName: this.manualName,
                            idCardNo: this.manualCode
                        }
                    ]
                    // userName: this.manualName,
                    // idCardNo: this.manualCode
                })
                errMsg = checkoutIdCardNoRes.errorList[0]?.errMsg
                // if (checkoutIdCardNoRes.errorList) {
                //     this.$message.error('该人员未加保')
                //     await loading.close()
                //     return
                // }
            } else {
                // 身份证号姓名校验
                const checkoutIdCardNoRes = await checkoutIdCardNoListTwo([
                    {
                        userName: this.manualName,
                        idCardNo: this.manualCode
                    }
                ])
                if (checkoutIdCardNoRes.code === 1) {
                    this.$message.error(
                        checkoutIdCardNoRes.data[0].msg as string
                    )
                    await loading.close()
                    return
                }
            }

            if (this.insurance === 'add') {
                var company: any = this.companyDetailList.filter(
                    (item: any) => item.serveCompanyId === this.companyDetailId
                )
                var workType: any = this.workTypeList.filter(
                    (item: any) => item.id === this.workTypeId
                )
            }

            const arr = this.addList
                .map((item: any) => {
                    if (item.idCardNo === this.manualCode) {
                        return item
                    }
                })
                .filter((n: any) => n)
            if (arr.length > 0) {
                this.$message.error('列表中已有该人员')
                return
            }
            await this.addList.push({
                userName: this.manualName,
                idCardNo: this.manualCode,
                // eslint-disable-next-line no-undef
                errMsg: this.insurance === 'reduce' && errMsg ? errMsg : '',
                serveCompanyName:
                    this.insurance === 'add' ? company[0].serveCompanyName : '',
                occupationName:
                    this.insurance === 'add' ? workType[0].name : '',
                joinType: 2,
                level: this.insurance === 'add' ? workType[0].level : '',
                serveCompanyId: this.companyDetailId,
                occupationId: this.workTypeId,
                specialAppoint: this.specialAppoint
            })
            this.addList.sort((a: any, b: any) => {
                return (b.errMsg ? 1 : 0) - (a.errMsg ? 1 : 0)
            })
            this.addCloneList = this.addList
            await this.interceptList()
            // companyDetailList workTypeList
            this.isManualPopup = false
            this.companyDetailList = []
            this.workTypeList = []
            this.companyDetailId = ''
            this.workTypeId = ''
            this.preparationRecord()
        },
        // 加保接口
        async insureAdd() {
            let isAdopt = false
            // const arr = [] as any
            this.addList.forEach((item: addListInformation) => {
                if (!item.occupationId) {
                    isAdopt = true
                }
            })
            if (isAdopt) {
                this.$message.error(
                    '当前有人未设置工种,或工种设置不正确,请重新设置'
                )
                return
            }
            const result = await insureAdd({
                companyPlanId: this.$route.params.id,
                insurStartDay: this.date,
                staffList: this.addList
            })
            this.isRenew = false
            if (result.code === 1 && result.errorList.length > 0) {
                this.isRemindPopup = false
                this.enterErrorList = result.errorList
                return
            }
            if (!result.isEnoughBalance && result.isSuccess === false) {
                this.$message.error('余额不足')
                return
            }
            this.$message.success('加保成功')
            this.isSuccessPopup = true
        },
        // 减保
        async insureReduce() {
            let arr = [] as any
            this.addList.forEach(
                (item: {
                    userName: string;
                    idCardNo: string;
                    errMsg?: string;
                }) => {
                    arr.push({
                        userName: item.userName,
                        idCardNo: item.idCardNo,
                        errMsg: item.errMsg
                    })
                }
            )
            arr = arr.filter((item: any) => !item.errMsg)
            // 减保前的 校验 检验他是否在保
            const checkResult = await insureCheckReduce({
                companyPlanId: this.$route.params.id,
                workerInfoList: arr
            })
            if (checkResult.errorList.length === 0) {
                const idS = [] as number[]
                checkResult.correctList.forEach((item: { id: number }) => {
                    idS.push(item.id)
                })
                const result = await insureReduce({
                    companyPlanId: this.$route.params.id,
                    insurEndDay: this.date,
                    ids: idS
                })
                this.$message.success('减保成功')
                this.isUninsuredPopup = false
                this.isSuccessPopup = true
            } else {
                this.isUninsuredPopup = true
                this.uninsuredList = checkResult.errorList
            }
            // const result = await insureReduce({})
        },
        // 关闭表格弹窗
        onFormError() {
            this.formErrorList = []
        },
        onNextMonth() {
            const time = new Date()
            const year = time.getFullYear()
            const month = time.getMonth() + 2
            this.date =
                dateFormat(
                    'YYYY-mm',
                    new Date(
                        (month === 13 ? year + 1 : year) +
                        '-' +
                        (month === 13 ? '01' : month)
                    )
                ) + '-01'
            this.datePickerDisabled = true
        },
        // 续保列表回显
        async getRenewalMonthNotify() {
            const result = await renewalMonthNotify({
                planId: this.$route.params.id
            })
            // addList
            const arr = result.staffList.map(
                (item: {
                    userName: string;
                    occupationLevel: number;
                    idCardNo: string;
                    occupationId: number;
                    occupationName: string;
                    serveCompanyId: number;
                    companyPlanName: string;
                    serveCompanyName: string;
                }) => {
                    return {
                        idCardNo: item.idCardNo,
                        joinType: 1,
                        level: item.occupationLevel,
                        occupationId: item.occupationId,
                        occupationName: item.occupationName,
                        serveCompanyId: item.serveCompanyId,
                        serveCompanyName: item.serveCompanyName,
                        userName: item.userName
                    }
                }
            )
            this.addList = arr
        },
        // 一键续保前置条件
        async onTestingInsure() {
            const result = await testingInsure({
                companyPlanId: Number(this.$route.params.id),
                insurStartDay: this.date,
                staffList: this.addList
            })
            this.testingInsureInfo = result
            if (result.errorList?.length > 0) {
                this.enterErrorList = result.errorList
            } else {
                this.isRenew = result.isSuccess
                this.isRenewAmount = !result.isSuccess
            }
        }
    }
})
