<template>
    <div>
        <div class="upload-box" style="width:100%">
            <el-upload class="upload-demo" :action="uploadAction" :headers="{ Authorization }" :on-success="fileSuccess"
                :on-error="fileEerror" :show-file-list='true' :before-upload="fileBeforeUpload" :file-list="fileList"
                :limit="limit" :on-exceed="handleExceed" :on-remove="fileRemove" accept=".mp4">
                <button class="btn-white-blue" style="width:130px;background:#1989FA;color:#fff;" @click="onBatchApplyEle">
                    <!-- <el-icon><video-camera /></el-icon> -->
                    <i class="el-icon el-icon-camera" />选择视频文件
                </button>
            </el-upload>
            <div class="upload-introduce">
                视频时间10s-60s 视频大小不超50M
            </div>
        </div>
        <el-dialog title="压缩视频" :width="500" :center="true" :close-on-press-escape="false" :close-on-click-modal="false"
            v-model.show="showDialog">
            <div>{{ message }}</div>
            <el-progress :percentage="progress" />
        </el-dialog>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import global from '@/utils/global'
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

export default defineComponent({
    props: {
        limitSize: {
            type: Number,
            default: 50
        },
        limit: {
            type: Number,
            default: 3
        },
        fileList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            Authorization: 'Bearer' + ' ' + global.token,
            uploadAction:
                process.env.VUE_APP_BASE_URL +
                '/rest/period-insur/serve-company/upload', // 上传路径
            showDialog: false,
            message: '',
            progress: 0,
            files: []
        }
    },
    watch: {
        fileList: {
            handler(newval) {
                this.files = newval
            },
            deep: true
        }
    },
    methods: {
        async fileBeforeUpload(file) {
            const url = URL.createObjectURL(file)
            const audioElement = new Audio(url)
            const duration = parseInt(audioElement.duration + '')
            const fileLength = parseInt(audioElement.duration + '')
            if (file.type !== 'video/mp4') {
                this.$message.error('请上传视频')
                return false
            }
            if (fileLength < 10 && fileLength > 100) {
                this.$message.error('视频不可小于10秒或大于100秒')
                return false
            }
            if ((file.size / 1024 / 1024) > this.limitSize) {
                // const compressFile = await this.compressVideo(file)
                // return compressFile
                this.$message.error(`请上传小于${this.limitSize}M的视频`)
                return false
            }
        },
        handleExceed() {
            this.$message.error(`最多上传${this.limit}个视频`)
        },
        async compressVideo(file) {
            return new Promise(async resolve => {
                this.showDialog = true
                const ffmpeg = createFFmpeg({
                    corePath: 'ffmpeg-core.js',
                    log: true,
                    progress: ({ ratio }) => {
                        this.progress = (ratio * 100).toFixed(2)
                        if (ratio === 1) {
                            this.showDialog = false
                        }
                    }
                })
                let { name, size } = file
                this.message = '正在加载\n'
                await ffmpeg.load()
                this.message = '开始压缩\n'
                ffmpeg.FS('writeFile', name, await fetchFile(file))
                let outputName = new Date().getTime()
                await ffmpeg.run('-i', name, '-b:v', '512k', `${outputName}.mp4`)
                this.message = '压缩完成'
                const data = await ffmpeg.FS('readFile', `${outputName}.mp4`)
                let newFile = new File([data], name)
                console.log(5555, data)
                console.log(6666, newFile)
                resolve(newFile)
            })
        },
        async fileSuccess(response, file, fileList) {
            var url = URL.createObjectURL(fileList[fileList.length - 1].raw)
            var audioElement = new Audio(url)
            var duration = parseInt(audioElement.duration + '')
            await audioElement.addEventListener('loadedmetadata', () => {
                console.log(parseInt(audioElement.duration + ''), '时长为秒，取整')// 时长为秒，取整
                const fileLength = parseInt(audioElement.duration + '')
                if (response.code === 500) {
                    this.$message.error(response.msg)
                    return
                }
                console.log(file, 'file')
                this.files.push({
                    name: file.name,
                    url: response.data.fileUrl,
                    fileSize: response.data.fileSize,
                    fileType: 'video',
                    fileUrl: response.data.fileUrl,
                    id: new Date().getTime()
                })
                this.$emit('update:fileList', this.files)
                this.$message.success('上传成功')
            })
        },
        fileEerror() {
            this.$message.error('文件上传失败')
        },
        // 删除文件
        fileRemove(file, uploadFiles) {
            console.log(file, uploadFiles, 'uploadFiles')
            const index = this.files.findIndex(i => i.id === file.id)
            console.log(2121, index);
            if (index !== -1) {
                this.files.splice(index, 1)
                this.$emit('update:fileList', this.files)
            }
            // this.files = this.files.filter((item) => item.id !== file.id)
        },
    }
})


</script>
<style lang="stylus" scoped>
.upload-box
    display flex
    align-items baseline
.upload-introduce
    margin-left 16px
    color #1989FA
</style>