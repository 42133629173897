
import {
    getInsureDetailInfo,
    getInsureDetailAdditionList,
    getInsureDetailPersonnelList,
    getInsureDetailAdditionExport,
    insureDetailAllExport,
    getPlanStatistics,
    PlanStatisticsDto,
    renewalMonthNotify,
    onLongInsuranceRevoke,
    checkStatusPeriod
} from '@/api/long-insure'
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import AlertBox from '@/components/alert-box.vue'
import tabCapsule from '@/components/tab-capsule.vue'
import { getsaasCompanyinfoapi } from '../api/base'
import { dateFormat, confirm, isWeixin } from '@/utils/util'
import { ElMessageBox, ElMessage } from 'element-plus'
import exportButton from '../components/export-button.vue'
export default defineComponent({
    name: 'LongInsureDetail',
    components: { AlertBox, tabCapsule, exportButton },
    data() {
        return {
            insurCompany: '',
            customerDate: '',
            countDate: '',
            tioInfo: {} as any,
            countPageSize: 5,
            countPageNum: 1,
            countTotal: 0,
            countList: [],
            personnelPageSize: 5,
            personnelPageNum: 1,
            personnelTotal: 100,
            personnelList: [],
            personneDate: '',
            isExportAllPopup: false,
            exportDate: '',
            exportType: '1',
            statisticsData: {} as PlanStatisticsDto,
            statisticsTab: 0,
            canInsureRenewal: false,
            isGoFluctuate: false,
            payTypeList: ['全部', '保障中', '待生效', '不在保'],
            payType: '',
            policyStatusNumber: '' as string | number
        }
    },

    async created() {
        this.customerDate = dateFormat('YYYY-mm', new Date())
        this.getInsureDetailInfo()
        this.getInsureDetailAdditionList()
        this.getInsureDetailPersonnelList()
        // 获取统计数据
        await this.getStatistics()
        this.onStatisticsTab(0)
        this.getRenewalMonthNotify()
    },
    methods: {
        // 长期保近期撤销
        onLongTermRevoke(id: number) {
            console.log(id)
            confirm('', '确定撤回吗？').then(async () => {
                await onLongInsuranceRevoke({
                    id: id,
                    companyPlanId: Number(this.$route.params.id) as number
                })
                await this.getInsureDetailAdditionList()
                this.$message.success('撤回成功')
            })
        },
        onRenew() {
            if (this.isGoFluctuate) {
                this.$message.error('当前时间段不可操作')
                return
            }
            this.$router.push({
                path:
                    '/long-insure-add-subtract/' +
                    'add' +
                    '/' +
                    this.$route.params.id + '/' + 'renewal'
            })
        },
        onInsureAdd() {
            getsaasCompanyinfoapi({ planId: this.$route.params.id }).then(async (res: any) => {
                console.log(res, 'fshgfgydshug')
                if (res.isAreaError && !res.isWhitelist) {
                    ElMessageBox.confirm(
                        '非常抱歉，当前方案不支持该（天津）地区投保，请选择“其他方案”继续投保',
                        '提示',
                        {
                            confirmButtonText: '其他方案',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }
                    )
                        .then(() => {
                            this.$router.back()
                        })
                        .catch(() => {
                            console.log('取消')
                        })
                } else {
                    const result = await checkStatusPeriod()
                    console.log(result, '加保')
                    if (!result.status) {
                        this.$message.error(result.msg)
                        return
                    }
                    this.$router.push({
                        path:
                            '/long-insure-add-subtract/' +
                            'add' +
                            '/' +
                            this.$route.params.id
                    })
                }
            })
        },
        onInsureSubtract() {
            if (this.isGoFluctuate) {
                this.$message.error('当前时间段不可操作')
                return
            }
            this.$router.push({
                path:
                    '/long-insure-add-subtract/' +
                    'reduce' +
                    '/' +
                    this.$route.params.id
            })
        },
        // 基本信息
        async getInsureDetailInfo() {
            const result = await getInsureDetailInfo({
                planId: this.$route.params.id,
                month: this.customerDate
            })
            // result.isEnd = result.endTime ? new Date(result.endTime).getTime() < new Date().getTime() : false

            console.log(result, 'result')
            this.insurCompany = result.insurCompany
            if (result.insurCompany === 'period_002_xdc') {
                const startTime = new Date('2022-09-30 18:00').getTime()
                const endTime = new Date('2022-09-30 24:00').getTime()
                const currentTime = new Date().getTime()
                if ((currentTime > startTime) && (currentTime < endTime)) {
                    this.isGoFluctuate = true
                }
            }
            this.tioInfo = result
        },
        handleCurrentChange(e: string) {
            console.log(e, 'eeeeee标签改变的e是什么')
            this.payType = e
            if (e === '全部') {
                // eslint-disable-next-line no-unused-expressions
                this.policyStatusNumber = ''
            } else if (e === '待生效') {
                // eslint-disable-next-line no-unused-expressions
                this.policyStatusNumber = 0
            } else if (e === '保障中') {
                // eslint-disable-next-line no-unused-expressions
                this.policyStatusNumber = 1
            } else if (e === '不在保') {
                // eslint-disable-next-line no-unused-expressions
                this.policyStatusNumber = 2
            }
            console.log(this.policyStatusNumber, 'policyStatusNumberpolicyStatusNumberpolicyStatusNumberpolicyStatusNumber')
            this.getInsureDetailPersonnelList()
        },
        onStatisticsTab(tab: number) {
            this.statisticsTab = tab
            const element = document.getElementById('echarts') as HTMLElement
            echarts.dispose(element)
            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} <br/>{c}人  ({d}%)'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['60%', '80%'],

                        label: {
                            position: 'inner',
                            formatter: '{b} {c}人'
                        },
                        data: [] as { value: number; name: string }[]
                    }
                ]
            }
            const charts = echarts.init(element)
            if (tab === 0) {
                option.series[0].data = this.statisticsData.occupationGroup.map(
                    item => ({
                        value: item.count,
                        name: item.occupationName
                    })
                )
            } else {
                option.series[0].data = this.statisticsData.serveCompanyGroup.map(
                    item => ({
                        value: item.count,
                        name: item.serveCompanyName
                    })
                )
            }
            charts.setOption(option)
        },
        async getStatistics() {
            this.statisticsData = await getPlanStatistics({
                companyPlanId: this.$route.params.id as string,
                policyStatus: ''
            })
        },
        // 加减记录列表
        async getInsureDetailAdditionList() {
            const result = await getInsureDetailAdditionList({
                pageSize: this.countPageSize,
                pageNum: this.countPageNum,
                companyPlanId: this.$route.params.id,
                insurStartDate: this.countDate
            })
            this.countList = result.list
            this.countTotal = result.total
        },
        // 改变加减保 每页条数
        countSizeChange() {
            console.log(this.countPageSize, 'countPageSize')
        },
        // 人员列表
        async getInsureDetailPersonnelList() {
            const result = await getInsureDetailPersonnelList({
                pageSize: this.personnelPageSize,
                pageNum: this.personnelPageNum,
                companyPlanId: this.$route.params.id,
                insurStartTime: this.personneDate,
                policyStatus: this.policyStatusNumber
            })
            result.list.map((item: any) => {
                item.encryptionIdCardNo = item.idCardNo.replace(
                    /(.{3})(?:\d+)(.{4})$/,
                    '$1*********$2'
                )
            })
            this.personnelList = result.list
            this.personnelTotal = result.total
        },
        // 导出加减保单条数据
        async getInsureDetailAdditionExport(id: string) {
            const result = await getInsureDetailAdditionExport({ id })
            console.log(result, 'result')
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result

            this.$message.success('导出成功！')
        },
        // 按天或按人导出
        async exportWhole() {
            console.log('导出')
            const result = await insureDetailAllExport({
                queryMonth: this.exportDate,
                queryType: this.exportType,
                companyPlanId: this.$route.params.id
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result
            this.$message.success('导出成功！')
            this.isExportAllPopup = false
        },
        async getRenewalMonthNotify() {
            const result = await renewalMonthNotify({
                planId: this.$route.params.id
            })
            this.canInsureRenewal = result.canInsureRenewal
            console.log(result, 'result月检测')
        },
        handleExport() {
            this.isExportAllPopup = true
        }
    }
})
