
import { defineComponent } from "vue";
import { BillDto } from "@/api/bill";
import {
    getEnsureCostListapi,
    insureDetailAllExport,
    exportperiodbill,
} from "@/api/long-insure";
import { ElMessage } from "element-plus";
import dayjs from "dayjs";
export default defineComponent({
    name: "LontInsureBill",
    data() {
        return {
            form: {
                type: "",
                filterDate: "",
            },
            isUp: false,
            filterDate: "",
            pageSize: 10, // 每页显示十条
            pageNum: 1, // 页数为1
            total: 0, // 总的为0
            tableData: [] as BillDto[], // 如同BillDto的数据
        };
    },
    created() {
        this.filterDate = dayjs().format("YYYY-MM");
        // 创建
        this.getBongBillRecord();
    },
    methods: {
        changeType(e: any) {
            console.log(e);
            this.form.filterDate = "";
        },
        onCancel() {
            this.isUp = false;
            this.form.type = "";
            this.form.filterDate = "";
        },
        async onSure() {
            console.log(this.form.type);

            if (this.form.type === "" || this.form.type === null) {
                ElMessage({
                    message: "请选择账单类型",
                    type: "warning",
                });
            } else if (
                this.form.filterDate === "" ||
                this.form.filterDate === null
            ) {
                ElMessage({
                    message: "请选择账单时间",
                    type: "warning",
                });
            } else {
                if (this.form.type !== "2") {
                    let res = await insureDetailAllExport({
                        queryMonth: this.form.filterDate,
                        queryType: this.form.type,
                        companyPlanId: "",
                    });
                    console.log(res);
                    window.location.href =
                        process.env.VUE_APP_BASE_URL +
                        "/common/download?delete=true&fileName=" +
                        res;
                    this.$message.success("导出成功！");
                    this.isUp = false;
                } else {
                    let res = await exportperiodbill({
                        effectiveDate: this.form.filterDate,
                        companyPlanId: "",
                    });
                    window.location.href =
                        process.env.VUE_APP_BASE_URL +
                        "/common/download?delete=true&fileName=" +
                        res;
                    this.$message.success("导出成功！");
                    this.isUp = false;
                }
            }
        },
        changeFilterDate(e: any) {
            console.log(e);
            this.getBongBillRecord();
        },
        onShowpopup() {
            console.log(111);
            this.isUp = true;
            this.form.type = "";
            this.form.filterDate = "";
        },
        async getBongBillRecord() {
            const result = await getEnsureCostListapi({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryMonth: this.filterDate,
            });
            console.log(result, "result");
            this.total = result.totalCount;
            this.tableData = result.list;
        },
        handleSizeChange() {
            this.pageNum = 1;
            this.getBongBillRecord();
        },
    },
});
