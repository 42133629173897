import request from './request'
// 获取补充材料长期保
export const getMaterialsLong = async (params: any) => {
    let res = await request.get('/period-insur/report-case/materials', {
        params: params
    })
    return res
}
// 获取补充材料长期保
export const addMaterialsLong = async (data: any) => {
    let res = await request.put('/period-insur/report-case/materials-add', data)
    return res
}
// 删除补充材料长期保
export const removeMaterialsLong = async (data: any) => {
    let res = await request.post(`/period-insur/report-case/materials-del/?fileId=${data}`)
    return res
}
// 下载附件长期保
export const exportImagesLong = async (params: any) => {
    let res = await request.get('/period-insur/report-case/download-report-case-images', {
        params: params,
        responseType: 'arraybuffer'
    })
    return res
}
// 获取补充材料日日保
export const getMaterialsDay = async (params: any) => {
    let res = await request.get('/wesure/wesure-report-case/materials', {
        params: params
    })
    return res
}
// 获取补充材料日日保
export const addMaterialsDay = async (data: any) => {
    let res = await request.put('/wesure/wesure-report-case/materials-add', data)
    return res
}
// 删除补充材料日日保
export const removeMaterialsDay = async (data: any) => {
    let res = await request.post(`/wesure/wesure-report-case/materials-del/?fileId=${data}`)
    return res
}
// 下载附件
export const exportImagesDay = async (params: any) => {
    let res = await request.get('/wesure/wesure-report-case/download-report-case-images', {
        params: params,
        responseType: 'arraybuffer'
    })
    return res
}
// 获取允许添加补充材料的保司
export const getMaterialInsureCompany = async () => {
    let res = await request.get('/period-insur/report-case/materials-insur-company')
    return res
}