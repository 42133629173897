
import { defineComponent } from 'vue'
import uploadFile from '@/components/upload-file.vue';
import { getCompanySeatInfo } from '@/api/recharge-record'
import dayjs from 'dayjs';
const expireDay = dayjs().add(30, 'day').format('YYYY-MM-DD') + ' 23:59:59'

export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        hasSeat: {
            type: [Number, String],
            default: 0
        },
        useSeat: {
            type: [Number, String],
            default: 0
        },
        willExpireSeatNumber: {
            type: [Number, String],
            default: 0
        }
    },
    components: {
        uploadFile
    },
    data() {
        return {
            visible: false,
            tableHeader: [
                {
                    prop: 'salesScenario',
                    label: '类型'
                },
                {
                    prop: 'buySeatCount',
                    label: '数量'
                },
                {
                    prop: 'updateTime',
                    label: '生效时间'
                },
                {
                    prop: 'seatExpirationTime',
                    label: '过期时间'
                },
            ],
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            }
        }
    },
    computed: {
        salesScenarioMap() {
            return (type: any) => {
                // 0：首签，1：续签,2:席位购买，3:续费+席位购买,4首签+席位购买
                const map = {
                    0: '首签',
                    1: '续签',
                    2: '席位购买',
                    3: '续费+席位购买',
                    4: '首签+席位购买'
                } as any
                return map[type]
            }
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.visible = newval
                if (newval) {
                    this.getData()
                }
            },
            immediate: true
        },
        visible(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        handleClose() {
            this.visible = false
        },
        getData() {
            getCompanySeatInfo({
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize
            }).then((res: any) => {
                console.log(res)
                this.tableData = res?.seatList?.list || []
                this.pageInfo.total = res?.seatList?.total || 0
            })
        },
        handleChange() {
            console.log(11111)
        },
        handleSizeChange(ev: any) {
            console.log(111, ev);
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: any) {
            this.pageInfo.pageNum = ev
            this.getData()
            console.log(222, ev);
        },
        willExpire(dueDate: any) {
            return dayjs(dueDate) < dayjs(expireDay)
        }
    }
})
