<template>
    <div v-if="showDialog" class="festival-container">
        <div class="festival-box">
            <div class="header-box">
                <div class="close pointer" @click="handleClose">
                    &times;
                </div>
            </div>
            <div class="content-box">
                <div class="set-lh">尊敬的用户：</div>
                <div class="set-lh">感谢您一直以来对我们的信任和支持！</div>
                <div class="set-lh">为了确保保司方案的长久稳定性，对部分保障条款做出相应调整，详情如下：</div>
                <div class="inner-box mg-t-12">
                    <div class="text-linear fs-12">关于在工作时间和工作岗位突发疾病死亡或者在48小时内经抢救无效死亡的方案调整</div>
                    <div class="flex gap-10 mg-t-12">
                        <div class="flex-item box flex-1">
                            <div class="fs-16 fc-darkred text-center">【100万方案】</div>
                            <div class="fs-12 mg-t-12">
                                保险人赔付限额不超过 30 万元/人。
                            </div>
                        </div>
                        <div class="flex-item box flex-1">
                            <div class="fs-16 fc-darkred text-center">【80万方案】</div>
                            <div class="fs-12 mg-t-12">
                                保险人赔付限额不超过 30 万元/人。
                            </div>
                        </div>
                        <div class="flex-item box flex-1">
                            <div class="fs-16 fc-darkred text-center">【50万方案】</div>
                            <div class="fs-12 mg-t-12">
                                保险人赔付限额不超过 20 万元/人。
                            </div>
                        </div>
                        <div class="flex-item box flex-1">
                            <div class="fs-16 fc-darkred text-center">【30万方案】</div>
                            <div class="fs-12 mg-t-12">
                                保险人赔付限额不超过 10 万元/人。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hint fs-12 mg-t-16">请您仔细阅读上述信息，如有任何疑问或需要帮助，请随时告诉我们。我们将尽全力为您提供支持和服务。再次感谢您的理解和配合！</div>
            <div class="fs-12 text-right mg-t-5">顺祝商祺！</div>
            <div class="button pointer" @click="handleClose">我已仔细阅读</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.showDialog = newval
            },
            immediate: true
        },
        showDialog(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        handleClose() {
            localStorage.setItem('sunnyNoticeClose', true)
            this.showDialog = false
        }
    }
}
</script>
<style lang="stylus" scoped>
.festival-container
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.7)
    z-index 100
.festival-box
    width 600px
    padding 0 20px 30px
    background #FFEBD2
    position absolute
    left 50%
    top 50%
    transform translate(-50%, -50%)
    border-radius 12px
    color #915435
.text-linear
    background linear-gradient(to right, #CA0200, #700000)
    -webkit-background-clip text
    background-clip text
    color transparent
.fs-34
    font-size 34px
.header-box
    background url(../../assets/dialog/sunny/header.png) no-repeat
    width 100%
    height 136px
    background-size cover
.content-box
    padding 20px 16px 28px
    background linear-gradient(242deg, #FFF4E3 3%, #FEF2E6 95%)
    border-radius 12px
    box-shadow 0px 2px 24px 0px #EBBB93
    margin-top -30px
.inner-box
    background #fff
    border-radius 12px
    padding 18px 12px
.fc-darkred
    color #B90100
.box
    background #FFF6F0
    border-radius 8px
    padding 12px 5px 18px
.text-right
    text-align right
    padding-right 10px
.button
    width 130px
    height 32px
    line-height 32px
    background #F66B3D
    border-radius 16px
    color #fff
    text-align center
    margin 12px auto 0
.close
    position absolute
    right 20px
    top 15px
    font-size 40px
.hint
    padding 0 16px
</style> 