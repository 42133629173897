
import global from '@/utils/global'
import { defineComponent } from 'vue'
import { ElMessageBox } from 'element-plus'
import {
	getDispatchList,
	cancelDispatchExamine,
	deleteDispatchExamine,
	addDispatch,
	getCompanyList,
	deleteOccupation
} from '@/api/long-insure'
import { BillDetailDto, BillDto } from '@/api/bill'
import { getPayrollList } from '@/api/payroll'
import batchPayroll from '@/components/batch-payroll.vue'
import alertBox from '@/components/alert-box.vue'
import uploadVideo from '@/components/upload-video/index.vue'
import LongInsureDispatchDrawer from './components/drawer/LongInsureDispatchDrawer.vue'
import DialogAddCompany from './components/long-insure-dispatch/DialogAddCompany.vue'
export default defineComponent({
	name: 'LongInsureDispatch',
	components: {
		batchPayroll,
		alertBox,
		uploadVideo,
		LongInsureDispatchDrawer,
		DialogAddCompany
	},
	data() {
		return {
			Authorization: 'Bearer' + ' ' + global.token,
			uploadAction:
				process.env.VUE_APP_BASE_URL +
				'/rest/period-insur/serve-company/upload', // 上传路径
			isBatchPay: false,
			pageSize: 10,
			pageNum: 1,
			total: 0,
			tableData: [] as BillDetailDto[],
			searchText: '',
			searchValue: '',
			detailInfo: {} as BillDto,
			selects: [] as string[],
			centerDialogVisible: false, // 弹窗
			fileSize: '',
			fileUrl: '',
			fileList: [] as any,
			isfileLengt: true,
			dispatchUnit: '', // 派遣单位
			companyList: [] as any, // 所有单位
			companyId: '', // 选择的单位id
			videoList: [] as string[],
			videoIndex: 0,
			showDetail: false,
			itemDetail: {},
			showAddCompany: false,
			curCompanyId: 0,
			curId: 0,
			addType: 'add', // 'add' 添加被派遣单位 'apply' 申请新工种
			statusMap: {
				0: '待审核',
				1: '已通过',
				2: '已驳回',
				3: '已禁用'
			}
		}
	},
	computed: {
		mapUrl() {
			return (list: any) => {
				if (list?.length) {
					return list.map((i: any) => i.fileUrl || i)
				}
				return []
			}
		},
		isFirstApply() {
			return (data: any) => {
				return data.status === 0 || (data.status === 1 && !data.occupationRecord) || data.status === 2
			}
		}
	},
	created() {
		this.getPayrollList()
		this.getCompanyList()
	},
	methods: {
		// 获取所有单位列表
		async getCompanyList() {
			const result = await getCompanyList()
			console.log(result, 'result获取所有单位列表')
			this.companyList = result.list
		},
		// 取消 派遣段位 审核
		async cancelDispatchExamine(id: string) {
			ElMessageBox.confirm(
				'确定撤销该条记录？',
				'撤销确认',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			)
				.then(async () => {
					const result = await cancelDispatchExamine({ id: id })
					await this.getPayrollList()
					this.$message.success('撤销成功！')
				})
		},
		// 删除派遣单位
		async deleteDispatchExamine(id: string) {
			console.log(id, 'id')
			ElMessageBox.confirm(
				'删除后该企业将无法投保，已申请是工种将被清除，确定删除？',
				'删除投保企业确认',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			)
				.then(async () => {
					const result = await deleteDispatchExamine({ id: id })
					await this.getPayrollList()
					this.$message.success('删除成功！')
				})
		},
		async getPayrollList() {
			const result = await getDispatchList({
				pageSize: this.pageSize,
				pageNum: this.pageNum,
				isGetFile: 1,
				searchText: this.searchValue
			})
			this.total = result.total
			this.tableData = result.list
		},
		handleSizeChange() {
			this.pageNum = 1
			this.getPayrollList()
		},
		search() {
			this.searchValue = this.searchText
			this.pageNum = 1
			this.getPayrollList()
		},
		onReset() {
			this.searchText = ''
			this.search()
		},
		handleSelectionChange(selects: { id: string }[]) {
			this.selects = selects.map(item => item.id)
		},
		// async fileBeforeUpload(file: any) {
		// 	var url = URL.createObjectURL(file)
		// 	var audioElement = new Audio(url)
		// 	var duration = parseInt(audioElement.duration + '')
		// 	audioElement.addEventListener('loadedmetadata', () => {
		// 		console.log(parseInt(audioElement.duration + ''), '时长为秒，取整')// 时长为秒，取整
		// 		const fileLength = parseInt(audioElement.duration + '')
		// 		if (fileLength < 10 && fileLength > 60) {
		// 			this.$message.error('视频不可小于10秒或大于60秒')
		// 			this.isfileLengt = false
		// 			return
		// 		}
		// 		if ((file.size / 1024 / 1024) > 50) {
		// 			this.$message.error('视频不可大于50M')
		// 			this.isfileLengt = false
		// 		}
		// 	})
		// },
		// fileSuccess(response: { code: number, msg: string, data: { fileSize: string, fileUrl: string } }, file: { raw: { name: string, type: string } }) {
		// 	console.log(file.raw, 'file.raw')
		// 	if (response.code === 500) {
		// 		this.$message.error(response.msg)
		// 		return
		// 	}
		// 	if (file.raw.type !== 'video/mp4') {
		// 		this.$message.error('请上传视频')
		// 		return
		// 	}
		// 	// if (!this.isfileLengt) {
		// 	//     this.$message.error('视频不可小于10秒或大于100秒')
		// 	//     return
		// 	// }
		// 	this.fileSize = response.data.fileSize
		// 	this.fileUrl = response.data.fileUrl
		// 	console.log(file, 'file', file.raw.type)
		// 	this.$message.success('上传成功')
		// },
		// fileEerror() {
		// 	this.$message.error('文件上传失败')
		// },
		// 被派遣单位
		// async addDispatchUnit() {
		// 	console.log('添加派遣单位')
		// 	console.log(this.companyId, 'companyId')
		// 	// if (!this.fileList?.length) {
		// 	//     this.$message.error('请至少上传一个视频')
		// 	//     return
		// 	// }
		// 	if (!this.fileUrl) {
		// 		this.$message.error('请至少上传一个视频')
		// 		return
		// 	}
		// 	const result = await addDispatch({
		// 		serveCompanyId: Number(this.companyId),
		// 		params: {
		// 			// video: this.fileList[0].fileUrl
		// 			video: this.fileUrl
		// 		}
		// 	})
		// 	console.log(result, 'result')
		// 	this.centerDialogVisible = false
		// 	await this.getPayrollList()
		// 	this.$message.success('提交被派遣单位成功')
		// },
		// 获取派遣单位视频
		onFileDetail(fileList: string[]) {
			console.log(23331, fileList)
			this.videoList = fileList
			console.log(fileList, 'fileList')
			console.log('123456789')
		},
		// 关闭视频查看弹窗
		onVideoCallback() {
			this.videoList = []
			this.videoIndex = 0
		},
		// 查看上一个视频
		onVideoUpper() {
			// videoList: [] as string[],
			// videoIndex: 0
			if (this.videoIndex === 0) {
				return
			}
			this.videoIndex = this.videoIndex -= 1
		},
		// 查看下一个视频
		onVideoNext() {
			if (this.videoIndex === (this.videoList.length - 1)) {
				return
			}
			this.videoIndex = this.videoIndex += 1
		},
		/**
		 * 点击详情按钮
		 * @param id
		 */
		onShowDetailDrawer(item: any) {
			this.itemDetail = item
			this.showDetail = true
		},
		onShowAddCompany() {
			this.addType = 'add'
			this.showAddCompany = true
		},
		onApplyNewOccupation(companyId: number, id: any = '') {
			this.addType = 'apply'
			this.curCompanyId = companyId
			this.curId = id
			this.showAddCompany = true
		},
		/**
		 * 撤销工种
		 */
		onWithDrawOccupation(id: number) {
			ElMessageBox.confirm(
				'确定撤销该条记录？',
				'撤销确认',
				{
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}
			)
				.then(async () => {
					const result = await deleteOccupation(id)
					await this.getPayrollList()
					this.$message.success('撤销成功！')
				})
		}
	}
})
