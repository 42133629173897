<template>
    <div v-if="showDialog" class="festival-container">
        <div class="festival-box">
            <!-- <div class="header-box">
                <div class="close pointer" @click="handleClose">
                    &times;
                </div>
            </div> -->
            <div class="wrapper" ref="scroll" @scroll="onScroll">
                <!-- <div class="wrapper"> -->
                <div class="flex-center bold fs-22">
                    <span class="blue">日日保</span>
                    <span class="deepblue-line">出险案件告知</span>
                </div>
                <div class="content-box fs-14">
                    <div class="mg-t-10">
                        <div class="item flex flex-ai-c">
                            <span class="icon-dot"></span>
                            <span class="mg-l-5 bold blue">出险后24小时之内操作在线报案</span>
                            <span class="deepblue">电脑端手机端都可操作</span>
                        </div>
                    </div>
                    <div class="mg-t-16">
                        <div class="item flex">
                            <span class="icon-dot"></span>
                            <div>
                                <span class="mg-l-5 bold blue">投保成功后，自承保之时起所涉及的出险案件必须提供现场监控视频</span>
                                <span class="deepblue">，以佐证出险时间。出险视频应当满足以下几点要求：</span>
                            </div>
                        </div>
                        <div class="itembox mg-t-5">
                            <div>1.需要看到具体时间 ，具体到 年/月/日/时/分/秒</div>
                            <div>2.视频时长需 2 分钟以上，内容包含出险前及出险后</div>
                            <div>3.尽可能看清出险员工正脸</div>
                        </div>
                    </div>
                    <div class="mg-t-16">
                        <div class="item flex flex-ai-c">
                            <span class="icon-dot"></span>
                            <span class="mg-l-5">员工二级以上公立医院治疗，紧急就诊仅限一级公立医院和卫生院（限额500内）</span>
                        </div>
                    </div>
                    <div class="mg-t-16">
                        <div class="item flex flex-ai-c">
                            <span class="icon-dot"></span>
                            <span class="mg-l-5">医药费理赔仅限社保内用药,自费药部分不予理赔且100免赔</span>
                            <span class="fc-lightgray">（依据方案）</span>
                        </div>
                    </div>
                    <div class="mg-t-16">
                        <div class="item flex">
                            <span class="icon-dot"></span>
                            <div>
                                <span class="mg-l-5 bold blue">医院所有材料须保存原件：</span>
                                <span class="">病历、发票、片子、片子报告、入院记录、费用清单、出院记录、建休证明、医嘱单手术记录、所有检查报告(须盖医院章)</span>
                            </div>
                        </div>
                    </div>
                    <div class="mg-t-16">
                        <div class="item flex flex-ai-c">
                            <span class="icon-dot"></span>
                            <span class="mg-l-5 blue bold">交通事故须出具事故责任认定书，赔偿调解书，对方赔偿明细</span>
                        </div>
                        <div class="itembox mg-t-5">
                            <div>1.对方主责/对方全赔(如果放弃向主责方索赔,则保司拒赔)</div>
                            <div>2.员工主要责任,按意外处理(上下班途中,员工受到非本人主要责任的交通事故可认定为工伤,员工主责 只能按照意外处理)</div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="{ 'button': true, 'pointer': true, 'disbtn': flag }" @click="handleClose">{{ flag ? '请上滑阅读完本条款'
                : '我已知晓' }}
            </div>
            <!-- <div class="button pointer" @click="handleClose">我已知晓
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false,
            reachBottomDistance: 10,
            flag: true
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.showDialog = newval
            },
            immediate: true
        },
        showDialog(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        onScroll(e) {
            let scrollTop = e.target.scrollTop
            let scrollHeight = e.target.scrollHeight
            let offsetHeight = Math.ceil(e.target.getBoundingClientRect().height)
            let currentHeight = scrollTop + offsetHeight + this.reachBottomDistance
            if (currentHeight >= scrollHeight && this.flag) {
                console.log('触底')
                this.flag = false
            }
        },
        handleClose() {
            // localStorage.setItem('sunnyNoticeClose', true)
            if (!this.flag) {
                this.showDialog = false
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
.itembox
    line-height 17px
    color #565868
    font-size 12px
    border-radius 8px
    opacity 1
    background #F7F8FA
    padding 8px 10px
    box-sizing border-box
.deepblue-line
    background: linear-gradient(90deg, #1C2A55 46%, #295886 100%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
.flex-center
    align-items center
    display flex
    justify-content center
.deepblue
    color #1C2B55
.blue
    color #EFAB48

.festival-container
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.7)
    z-index 100
.festival-box
    width 584px
    background-image url(../../assets/dialog/case-notice/bg-3.png)
    background-size 100% 100%
    position absolute
    height 500px
    left 50%
    top 45%
    transform translate(-50%, -50%)
.disbtn
    background #fabb6d !important
.button
    width 170px
    height 35px
    line-height 35px
    background linear-gradient(100deg, #F2C14B 3%, #EFAB48 36%, #EC9245 73%)
    border-radius 16px
    color #fff
    text-align center
    margin 12px auto 14px
.close
    position absolute
    right 20px
    top 40px
    font-size 32px
.fs-34
    font-size 34px
.header-box
    width 100%
    color #fff
.pd-b-15
    padding-bottom 15px
.pd-t-26
    padding-top 26px

.wrapper ::-webkit-scrollbar
    display none
.wrapper
    padding 0 16px 10px
    margin-top 130px
    height 364px
    overflow auto
    box-sizing border-box
    scrollbar-width none
    -ms-overflow-style none
.content-box
    border-radius 12px
    color #1F2021
.icon-dot
    display block
    width 12px
    flex-shrink 0
    height 12px
    background url(../../assets/dialog/case-notice/icon-dot-3.png) no-repeat
.mg-l-15
    margin-left 17px
.fc-lightgray
    color #686868
.fc-blue
    color #061EB6
</style>
