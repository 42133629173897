
import { defineComponent } from 'vue'
import { getReportList } from '@/api/long-insure'
import { BillDetailDto, BillDto } from '@/api/bill'
import { getPayrollList, payrollDelete } from '@/api/payroll'
import { exportPayroll } from '@/api/export'
import batchPayroll from '@/components/batch-payroll.vue'
import addMaterial from '@/components/add-material.vue';
import { getMaterialInsureCompany } from '@/api/add-material.ts';

export default defineComponent({
    name: 'LongInsureReport',
    components: {
        batchPayroll,
        addMaterial
    },
    data() {
        return {
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            curItem: {},
            showAdd: false,
            insureCompanyList: [] as any,
            uploadStatus: ''
        }
    },
    computed: {
        canShow() {
            return (insureCompanyType: string) => {
                return this.insureCompanyList.some((i: string) => i === insureCompanyType)
            }
        }
    },
    async created() {
        this.insureCompanyList = await getMaterialInsureCompany() || []
        this.getPayrollList()
    },
    methods: {
        async getPayrollList() {
            const result = await getReportList({
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                uploadStatus: this.uploadStatus
            })
            this.total = result.total
            this.tableData = result.list
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getPayrollList()
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset() {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange(selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        /**
         * 删除勾选工资单
         */
        async onDelete() {
            const str = this.selects.join(',')
            if (str.length === 0) {
                this.$message.warning('请至少选择一个！')
                return
            }
            await payrollDelete(str)
            this.getPayrollList()
            this.$message.success('删除成功！')
        },
        showAddMaterial(item: any) {
            this.curItem = item
            this.showAdd = true
        }
    }
})
