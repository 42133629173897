
import { defineComponent } from 'vue'
import { insureCaseList } from '@/api/insure-deduction'
import { BillDetailDto, BillDto } from '@/api/bill'
import { getPayrollList } from '@/api/payroll'
import addMaterial from '@/components/add-material.vue';
import { getMaterialInsureCompany } from '@/api/add-material.ts';
// import batchPayroll from '@/components/batch-payroll.vue'
export default defineComponent({
    name: 'InsureReport',
    components: {
        // batchPayroll
        addMaterial
    },
    data() {
        return {
            stateCode: '',
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            showAdd: false,
            curItem: {},
            insureCompanyList: [] as any,
            uploadStatus: '',
        }
    },
    computed: {
        canShow() {
            return (insureCompanyType: string) => {
                return this.insureCompanyList.some((i: string) => i === insureCompanyType)
            }
        }
    },
    async created() {
        this.insureCompanyList = await getMaterialInsureCompany() || []
        this.getPayrollList()
    },
    methods: {
        // 改变状态
        onChangeState() {
            this.handleSizeChange()
        },
        async getPayrollList() {
            const result = await insureCaseList({
                reportStatus: this.stateCode,
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                insureCompanyType: 'insure_type_rrb',
                uploadStatus: this.uploadStatus
            })
            console.log(result, 'result')
            this.total = result.total
            this.tableData = result.rows
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getPayrollList()
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset() {
            this.searchText = ''
            this.stateCode = ''
            this.search()
        },
        handleSelectionChange(selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        showAddMaterial(item: any) {
            console.log(111, item);
            this.curItem = item
            this.showAdd = true
        }
    }
})
